@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css o App.css */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
body, html, #root, .App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}